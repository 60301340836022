import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])$/;

const PreChatFormView = ({
  formRef, name, email, message, readOnly, setFieldValue, onSubmit,
}) => (
  <form ref={formRef} key="not-sent" className="offline-form">
    <div className="content">
      <div className="section">
        <label htmlFor="name" className="label">
          {t('zendesk.pre_chat.name')}
          <input
            id="name"
            type="text"
            value={name}
            onChange={setFieldValue}
            readOnly={readOnly}
            required
            maxLength="255"
          />
        </label>
      </div>
      <div className="section">
        <label htmlFor="email" className="label">
          {t('zendesk.pre_chat.email')}
          <input
            id="email"
            type="email"
            value={email}
            onChange={setFieldValue}
            readOnly={readOnly}
            required
            pattern={EMAIL_REGEX.source}
          />
        </label>
      </div>
      <div className="section">
        <label htmlFor="message" className="label">
          {t('zendesk.pre_chat.message')}
          <textarea
            id="message"
            value={message}
            onChange={setFieldValue}
            required
          />
        </label>
      </div>
    </div>
    <div className="button-container">
      <button type="submit" className="action-button button-send" onClick={onSubmit}>
        {t('zendesk.pre_chat.send')}
      </button>
    </div>
  </form>
);

PreChatFormView.propTypes = {
  formRef: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PreChatFormView;
