import PropTypes from 'prop-types';

import { Icon } from 'bv-components';
import { t } from 'bv-i18n';

const RatingMessage = ({ agent, shouldDisplay, hasRating }) => {
  const { sendChatRating } = window.zChat;

  if (!shouldDisplay) return null;

  return (
    hasRating ? (
      <div className="chat-rating-card">
        <div>{t('zendesk.chat.rating_success')}</div>
        <div className="buttons-container" title={t('zendesk.chat.rate_again')}>
          <Icon id="refresh" onClick={() => sendChatRating(null)} />
        </div>
      </div>
    ) : (
      <div className="chat-rating-card">
        {t('zendesk.chat.rating_request', { name: agent.display_name })}
        <div className="buttons-container">
          <div title={t('zendesk.chat.rate_good')} className="button">
            <span onClick={() => sendChatRating('good')} className="action-button zendesk-icon zendesk-thumbs-up-icon" />
          </div>
          <div title={t('zendesk.chat.rate_bad')} className="button">
            <span onClick={() => sendChatRating('bad')} className="action-button zendesk-icon zendesk-thumbs-down-icon" />
          </div>
        </div>
      </div>
    )
  );
};

RatingMessage.propTypes = {
  shouldDisplay: PropTypes.bool,
  hasRating: PropTypes.bool,
  agent: PropTypes.instanceOf(Object).isRequired,
};

RatingMessage.defaultProps = {
  hasRating: false,
  shouldDisplay: true,
};

export default RatingMessage;
