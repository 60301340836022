import {
  ACCOUNT_DATA_LOADED_ZENDESK,
  INIT_ACCOUNT_DATA_ZENDESK,
  SET_OPEN_ZENDESK,
  SET_HIDE_ZENDESK,
  SEND_VISITOR_MESSAGE,
  SEND_VISITOR_FILE,
  END_CHAT,
} from './actions';

export const setIsOpen = (isOpen) => ({
  type: SET_OPEN_ZENDESK,
  isOpen,
});

export const setIsHidden = (isHidden) => ({
  type: SET_HIDE_ZENDESK,
  isHidden,
});

export const initFetchAccountData = () => ({
  type: INIT_ACCOUNT_DATA_ZENDESK,
});

export const accountDataLoaded = (data) => ({
  type: ACCOUNT_DATA_LOADED_ZENDESK,
  data,
});

export const sendVisitorMessage = (message) => ({
  type: SEND_VISITOR_MESSAGE,
  message,
});

export const sendVisitorFile = (file) => ({
  type: SEND_VISITOR_FILE,
  file,
});

export const endChat = (keepOpen) => ({
  type: END_CHAT,
  keepOpen,
});
