import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MessageListView from './message_list_view';

const MessageListContainer = ({
  agents, messages, queuePosition, sendVisitorMessage, hasRating, lastRatingRequestTimestamp,
}) => {
  const messagesListRef = useRef(null);

  useEffect(() => {
    if (messagesListRef?.current) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
    }
  });

  return (
    <MessageListView
      agents={agents}
      messages={messages}
      queuePosition={queuePosition}
      messagesListRef={messagesListRef}
      sendVisitorMessage={sendVisitorMessage}
      hasRating={hasRating}
      lastRatingRequestTimestamp={lastRatingRequestTimestamp}
    />
  );
};

MessageListContainer.propTypes = {
  agents: PropTypes.instanceOf(Object).isRequired,
  messages: PropTypes.arrayOf(Object).isRequired,
  queuePosition: PropTypes.number.isRequired,
  sendVisitorMessage: PropTypes.func.isRequired,
  hasRating: PropTypes.bool.isRequired,
  lastRatingRequestTimestamp: PropTypes.number.isRequired,
};

export default MessageListContainer;
