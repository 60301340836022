import PropTypes from 'prop-types';
import Avatar from './avatar';

const TypingAgents = ({ agents }) => (
  <>
    {Object.values(agents).filter((a) => a.typing).map((agent) => (
      <div key={agent.nick} className="chat-msg-container agent">
        <div className="avatar-container">
          <Avatar entity={agents[agent.nick]} />
        </div>
        <div className="chat-msg-wrapper">
          <div className="chat-msg">
            <div className="typing-indicator">
              <div className="typing-indicator-part">•</div>
              <div className="typing-indicator-part">•</div>
              <div className="typing-indicator-part">•</div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

TypingAgents.propTypes = {
  agents: PropTypes.instanceOf(Object).isRequired,
};

export default TypingAgents;
