import { useState, useEffect, useRef } from 'react';
import { noop } from 'underscore';
import PropTypes from 'prop-types';
import { useLoggedIn } from 'bv-hooks';
import { presentedZendeskEvents } from '../../helpers/constants';
import AppView from './app_view';
import Error from '../error';
import Loading from '../loading';

const AppContainer = ({
  keepHidden, isOpen, isHidden, isChatting, setIsOpen, setIsHidden,
  accountData, fetchAccountData, dispatch, endUserChat, currentPath,
}) => {
  const { location } = window;
  const [isPreviousChatCleared, setIsPreviousChatCleared] = useState(false);
  const hasInitializedVisitorPath = useRef(false);
  const loggedIn = useLoggedIn();

  useEffect(() => {
    if (!keepHidden) setIsHidden(false);
  }, []);

  useEffect(() => {
    if (window.zChat) {
      if (hasInitializedVisitorPath.current) {
        window.zChat.sendVisitorPath({
          title: currentPath,
          url: `${location.protocol}//${location.host}${currentPath}`,
        });
      }
      hasInitializedVisitorPath.current = true;
    }
  }, [currentPath]);

  useEffect(() => {
    if (window.zChat) {
      if (!window.zChat.getConnectionStatus()) {
        window.zChat.endChat({
          clear_dept_id_on_chat_ended: true,
        }, () => {
          endUserChat(true);
          setIsPreviousChatCleared(true);
        });
      } else {
        setIsPreviousChatCleared(true);
      }

      const events = presentedZendeskEvents(dispatch);

      events.forEach(({ name, handler }) => window.zChat.on(name, handler));
      if (loggedIn) fetchAccountData();
      setIsOpen(true);

      return () => {
        events.forEach(({ name, handler }) => window.zChat.un(name, handler));
      };
    }
    return noop;
  }, [loggedIn]);

  if (isHidden) return null;

  if (!window.zChat) return <Error />;

  if (isOpen && (accountData.loading || !isPreviousChatCleared)) return <Loading />;

  return <AppView isOpen={isOpen} isChatting={isChatting} setIsOpen={setIsOpen} />;
};

AppContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  isChatting: PropTypes.bool.isRequired,
  endUserChat: PropTypes.func.isRequired,
  accountData: PropTypes.instanceOf(Object).isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setIsHidden: PropTypes.func.isRequired,
  fetchAccountData: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  keepHidden: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default AppContainer;
