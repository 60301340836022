import PropTypes from 'prop-types';
import { isAgent } from '../helpers/chat';

const Avatar = ({ entity }) => {
  const getVisitorSvg = () => (
    <span className="zendesk-icon zendesk-avatar-icon" />
  );

  const style = {};
  let child;
  if (entity && isAgent(entity.nick) && entity.avatar_path) {
    style.backgroundImage = `url('${entity.avatar_path}')`;
    style.backgroundColor = 'none';
  } else {
    child = getVisitorSvg();
  }

  return (
    <div className="avatar" style={style} title={entity ? entity.display_name : 'Agent'}>
      {child}
    </div>
  );
};

Avatar.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
};

export default Avatar;
