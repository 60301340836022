import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setIsOpen } from '../ducks/action_creators';

const TopBar = ({ title, onMinimize }) => (
  <div className="status-container">
    {title}
    <div className="minimize-button" onClick={onMinimize}>
      <div className="minimize-button-bar" />
    </div>
  </div>
);

TopBar.propTypes = {
  title: PropTypes.string.isRequired,
  onMinimize: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onMinimize: () => dispatch(setIsOpen(false)),
});

export default connect(null, mapDispatchToProps)(TopBar);
