import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { v as bvVar } from 'bv';
import LiveChat from './components/app';
import zendeskLiveChat from './ducks';

const { addReducers, store } = window.reduxState;

addReducers({
  zendeskLiveChat,
});

const Zendesk = ({ keepHidden }) => {
  window.zChat.init({
    account_key: bvVar('zendeskConfig').accountKey,
    suppress_console_error: false,
  });

  return (
    <Provider store={store}>
      <div className="index">
        <LiveChat keepHidden={keepHidden} />
      </div>
    </Provider>
  );
};

Zendesk.propTypes = {
  keepHidden: PropTypes.bool,
};

Zendesk.defaultProps = {
  keepHidden: false,
};

export default Zendesk;
