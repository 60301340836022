import { connect } from 'react-redux';
import { compose } from 'underscore';
import { sendVisitorMessage } from '../../ducks/action_creators';
import {
  getAgents, getHasRating, getLastRatingRequestTimestamp, getMessages, getQueuePosition,
} from '../../selectors';
import MessageListContainer from './message_list_container';

const mapStateToProps = (state) => ({
  agents: getAgents(state),
  messages: getMessages(state),
  queuePosition: getQueuePosition(state),
  hasRating: getHasRating(state),
  lastRatingRequestTimestamp: getLastRatingRequestTimestamp(state),
});

const mapDispatchToProps = (dispatch) => ({
  sendVisitorMessage: compose(dispatch, sendVisitorMessage),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageListContainer);
