import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const SystemMessage = ({ message }) => {
  const getMessageByType = (msg) => {
    switch (msg.type) {
      case 'chat.memberjoin':
        return t('zendesk.chat.joined_chat', { name: message.display_name });
      case 'chat.memberleave':
        return t('zendesk.chat.left_chat', { name: message.display_name });
      case 'chat.rating':
        if (!message.new_rating) {
          return t('zendesk.chat.rating_removed');
        }
        return t('zendesk.chat.rating_success', { rating: message.new_rating });
      default:
        return '';
    }
  };

  return (
    <div className="system-msg-container">
      <span className="system-msg">{getMessageByType(message)}</span>
    </div>
  );
};

SystemMessage.propTypes = {
  message: PropTypes.instanceOf(Object).isRequired,
};

export default SystemMessage;
