import PropTypes from 'prop-types';
import Home from '../home';
import Bubble from '../bubble';

const AppView = ({
  isOpen, isChatting, setIsOpen,
}) => (
  <>
    {!isOpen && isChatting && <Bubble onClick={() => setIsOpen(true)} />}
    {isOpen && (
      <div className="zendesk-widget-container">
        <Home />
      </div>
    )}
  </>
);

AppView.propTypes = {
  isOpen: PropTypes.bool,
  isChatting: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
};

AppView.defaultProps = {
  isOpen: false,
  isChatting: false,
};

export default AppView;
