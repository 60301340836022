import { connect } from 'react-redux';
import { getAccountData, getConnectionStatus, getAllDepartments } from '../../selectors';
import HomeContainer from './home_container';

const mapStateToProps = (state) => ({
  connectionStatus: getConnectionStatus(state),
  accountData: getAccountData(state),
  departments: getAllDepartments(state),
});

export default connect(mapStateToProps)(HomeContainer);
