import { isTrigger, isAgent, getMemberType } from '../helpers/chat';
import {
  SET_OPEN_ZENDESK,
  SET_HIDE_ZENDESK,
  INIT_ACCOUNT_DATA_ZENDESK,
  ACCOUNT_DATA_LOADED_ZENDESK,
  zendeskEvents,
  SEND_VISITOR_MESSAGE,
  SEND_VISITOR_FILE,
  END_CHAT,
  ZENDESK_CHAT_MEMBER_JOIN,
  ZENDESK_CHAT_MEMBER_LEAVE,
  ZENDESK_CHAT_WAIT_QUEUE,
  ZENDESK_CHAT_QUEUE_POSITION,
  ZENDESK_CHAT_FILE,
  ZENDESK_CHAT_MSG,
  ZENDESK_CHAT_TYPING,
  ZENDESK_CHAT_REQUEST_RATING,
  ZENDESK_CHAT_RATING,
} from './actions';

const initialState = {
  // isOpen: true = show chat window, false = show chat bubble
  isOpen: false,
  // isHidden: true = show app container, false = hide app container
  isHidden: true,
  connectionStatus: 'connecting',
  accountStatus: 'offline',
  accountData: {
    loading: false,
  },
  departments: {},
  visitor: {},
  agents: {},
  isChatting: false,
  chats: [],
  lastTimestamp: 0,
  queuePosition: 0,
  hasRating: false,
  lastRatingRequestTimestamp: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case zendeskEvents.CONNECTION_UPDATE:
      return {
        ...state,
        connectionStatus: action.data,
      };
    case zendeskEvents.ACCOUNT_STATUS:
      return {
        ...state,
        accountStatus: action.data,
      };
    case zendeskEvents.DEPARTMENT_UPDATE:
      return {
        ...state,
        departments: {
          ...state.departments,
          [action.data.name]: action.data,
        },

      };
    case zendeskEvents.VISITOR_UPDATE:
      return {
        ...state,
        visitor: {
          ...state.visitor,
          ...action.data,
        },
      };
    case zendeskEvents.AGENT_UPDATE:
      return {
        ...state,
        agents: {
          ...state.agents,
          [action.data.nick]: {
            ...action.data,
            typing: !!state.agents[action.data.nick]?.typing,
          },
        },
      };
    case SET_OPEN_ZENDESK:
      return {
        ...state,
        isOpen: action.isOpen,
      };
    case SET_HIDE_ZENDESK:
      return {
        ...state,
        isHidden: action.isHidden,
      };
    case INIT_ACCOUNT_DATA_ZENDESK:
      return {
        ...state,
        accountData: {
          loading: true,
        },
      };
    case ACCOUNT_DATA_LOADED_ZENDESK:
      return {
        ...state,
        accountData: {
          loading: false,
          csLevel: action.data.customer_service_level,
          email: action.data.email,
          name: `${action.data.first_name} ${action.data.last_name}`.trim(),
          accountNumber: action.data.account_number,
          countryCode: action.data.country_code,
        },
      };
    // chat events
    case SEND_VISITOR_MESSAGE:
    case SEND_VISITOR_FILE:
      return {
        ...state,
        lastTimestamp: state.lastTimestamp + 1,
        chats: [
          ...state.chats, {
            [state.lastTimestamp + 1]: {
              type: action.type === SEND_VISITOR_MESSAGE ? ZENDESK_CHAT_MSG : ZENDESK_CHAT_FILE,
              display_name: state.visitor.display_name,
              nick: isTrigger(state.visitor.nick || 'visitor:')
                ? `agent:trigger:${state.visitor.display_name}` : state.visitor.nick || 'visitor:',
              timestamp: state.lastTimestamp + 1,
              ...(action.type === SEND_VISITOR_MESSAGE
                ? { msg: action.message } : { attachment: action.file }),
              source: 'local',
              member_type: getMemberType(state.visitor.nick || 'visitor:'),
            },
          },
        ],
      };
    case END_CHAT:
      return {
        ...state,
        isOpen: action.keepOpen ? state.isOpen : false,
        visitor: {},
        agents: {},
        isChatting: false,
        chats: [],
        lastTimestamp: 0,
        queuePosition: 0,
      };
    case zendeskEvents.CHAT: {
      // Web SDK events
      const newState = {
        ...state,
        agents: { ...state.agents },
        visitor: { ...state.visitor },
      };

      if (action?.data?.timestamp) {
        newState.lastTimestamp = action.data.timestamp;
      }

      switch (action.data.type) {
        case ZENDESK_CHAT_MEMBER_JOIN:
          if (isAgent(action.data.nick)) {
            newState.agents[action.data.nick] = {
              ...(newState.agents[action.data.nick] || {}),
              nick: action.data.nick,
            };
          } else {
            newState.visitor = {
              ...newState.visitor,
              nick: action.data.nick,
            };
            newState.isChatting = true;
          }

          return {
            ...newState,
            chats: [
              ...state.chats, {
                [action.data.timestamp]: {
                  ...action.data,
                },
              },
            ],
          };
        case ZENDESK_CHAT_MEMBER_LEAVE:
          if (!isAgent(action.data.nick)) {
            newState.isChatting = false;
          }

          return {
            ...newState,
            chats: [
              ...state.chats, {
                [action.data.timestamp]: {
                  ...action.data,
                },
              },
            ],
          };
        case ZENDESK_CHAT_WAIT_QUEUE:
          return {
            ...newState,
            queuePosition: action.data.wait_queue,
          };
        case ZENDESK_CHAT_QUEUE_POSITION:
          return {
            ...newState,
            queuePosition: action.data.queue_position,
          };
        case ZENDESK_CHAT_FILE:
        case ZENDESK_CHAT_MSG:
          return {
            ...newState,
            isOpen: true,
            isHidden: false,
            chats: [
              ...state.chats, {
                [action.data.timestamp]: {
                  ...action.data,
                  nick: isTrigger(action.data.nick) ? `agent:trigger:${action.data.display_name}` : action.data.nick,
                  member_type: getMemberType(action.data.nick),
                },
              }],
          };
        case ZENDESK_CHAT_TYPING: {
          // Ensure that triggers are uniquely identified by their display names
          const agent = isTrigger(action.data.nick) ? {
            nick: `agent:trigger:${action.data.display_name}`,
            display_name: action.data.display_name,
          } : state.agents[action.data.nick];

          return {
            ...state,
            agents: {
              ...state.agents,
              [agent.nick]: {
                ...agent,
                typing: action.data.typing,
              },
            },
          };
        }
        case ZENDESK_CHAT_REQUEST_RATING:
          return {
            ...newState,
            chats: [
              ...state.chats, {
                [action.data.timestamp]: {
                  ...action.data,
                },
              },
            ],
            lastRatingRequestTimestamp: action.data.timestamp,
          };
        case ZENDESK_CHAT_RATING:
          return {
            ...newState,
            chats: [
              ...state.chats,
              {
                [action.data.timestamp]: {
                  ...action.data,
                },
              },
            ],
            hasRating: !!action.data.new_rating,
          };
        default:
          return state;
      }
    }
    default:
      return state;
  }
};
