import { connect } from 'react-redux';
import { getAccountData } from '../../selectors';
import { sendVisitorMessage } from '../../ducks/action_creators';
import PreChatFormContainer from './pre_chat_form_container';

const mapStateToProps = (state) => ({
  accountData: getAccountData(state),
});

const mapDispatchToProps = { sendVisitorMessage };

export default connect(mapStateToProps, mapDispatchToProps)(PreChatFormContainer);
