import { t } from 'bv-i18n';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TopBar from './top_bar';
import Chat from './chat';
import PreChatForm from './pre_chat_form';
import { getIsChatting } from '../selectors';

const Widget = ({ isChatting }) => (
  <>
    <TopBar title={t('zendesk.live_chat')} />
    {isChatting ? <Chat /> : <PreChatForm />}
  </>
);

Widget.propTypes = {
  isChatting: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isChatting: getIsChatting(state),
});

export default connect(mapStateToProps)(Widget);
