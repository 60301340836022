import PropTypes from 'prop-types';

const Bubble = ({ onClick }) => (
  <div className="chat-button" onClick={onClick}>
    <span className="zendesk-icon zendesk-bubble-icon" />
  </div>
);

Bubble.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Bubble;
