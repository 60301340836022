import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { t } from 'bv-i18n';
import { getUserDepartment } from '../../helpers/routing';
import Widget from '../widget';
import MessageUs from '../message_us';

const HomeContainer = ({ connectionStatus, departments, accountData }) => {
  if (connectionStatus === 'connecting') return <Spinner />;
  if (connectionStatus === 'closed') return <MessageUs title={t('zendesk.leave_us_a_message')} />;

  const userDepartment = getUserDepartment(departments, accountData.csLevel);

  if (!userDepartment) return <MessageUs title={t('zendesk.leave_us_a_message')} />;

  window.zChat.setVisitorDefaultDepartment(departments[userDepartment].id);
  return <Widget />;
};

HomeContainer.propTypes = {
  connectionStatus: PropTypes.string.isRequired,
  departments: PropTypes.instanceOf(Object).isRequired,
  accountData: PropTypes.instanceOf(Object).isRequired,
};

export default HomeContainer;
