import { createSelector } from 'reselect';

const getPropertyFromDomain = (property) => (state) => state.zendeskLiveChat[property];

const domain = (state) => state.zendeskLiveChat;

export const getIsOpen = getPropertyFromDomain('isOpen');

export const getIsHidden = getPropertyFromDomain('isHidden');

export const getConnectionStatus = getPropertyFromDomain('connectionStatus');

export const getAccountData = getPropertyFromDomain('accountData');

export const getAllDepartments = getPropertyFromDomain('departments');

export const getIsChatting = getPropertyFromDomain('isChatting');

export const getVisitorData = getPropertyFromDomain('visitor');

export const getIsOffline = createSelector(
  domain,
  (state) => state.accountStatus === 'offline' && !state.isChatting,
);

export const getAgents = getPropertyFromDomain('agents');

export const getMessages = createSelector(
  domain,
  (state) => state.chats.map((c) => Object.values(c)[0]),
);

export const getQueuePosition = createSelector(
  domain,
  (state) => state.queuePosition || 0,
);

export const getHasRating = getPropertyFromDomain('hasRating');

export const getLastRatingRequestTimestamp = getPropertyFromDomain(
  'lastRatingRequestTimestamp',
);
