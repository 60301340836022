import { isEmpty } from 'underscore';
import { accountData } from 'bv';
import { initFetchAccountData, accountDataLoaded } from './action_creators';
import { getAccountData } from '../selectors';

export const fetchAccountData = () => (dispatch, getState) => {
  if (isEmpty(getAccountData(getState()))) {
    return;
  }

  dispatch(initFetchAccountData());

  accountData().then((data) => dispatch(accountDataLoaded(data)));
};
