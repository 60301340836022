import { values } from 'underscore';
import { zendeskEvents } from '../ducks/actions';

export const VISITOR = 'visitor';
export const AGENT = 'agent';

export const presentedZendeskEvents = (dispatch) => (
  values(zendeskEvents).reduce((acc, event) => {
    acc.push({
      name: event.replace('zendesk/', '').toLowerCase(),
      handler: (data) => (
        dispatch({ type: event, data })
      ),
    });
    return acc;
  }, [])
);
