import { connect } from 'react-redux';
import { compose } from 'underscore';
import { getIsOffline } from '../../selectors';
import { sendVisitorMessage, sendVisitorFile, endChat } from '../../ducks/action_creators';
import ChatContainer from './chat_container';

const mapStateToProps = (state) => ({
  isOffline: getIsOffline(state),
});

const mapDispatchToProps = (dispatch) => ({
  sendVisitorMessage: compose(dispatch, sendVisitorMessage),
  sendVisitorFile: compose(dispatch, sendVisitorFile),
  endUserChat: compose(dispatch, endChat),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer);
