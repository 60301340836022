import { connect } from 'react-redux';
import { compose } from 'underscore';
import { routing as routingSelectors } from 'redux-selectors';
import { fetchAccountData } from '../../ducks/thunks';
import { setIsOpen, setIsHidden, endChat } from '../../ducks/action_creators';
import {
  getAccountData,
  getIsOpen,
  getIsHidden,
  getIsChatting,
} from '../../selectors';
import AppContainer from './app_container';

const { getCurrentPath } = routingSelectors;

const mapStateToProps = (state) => ({
  currentPath: getCurrentPath(state),
  isOpen: getIsOpen(state),
  isHidden: getIsHidden(state),
  isChatting: getIsChatting(state),
  accountData: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountData: compose(dispatch, fetchAccountData),
  setIsOpen: compose(dispatch, setIsOpen),
  setIsHidden: compose(dispatch, setIsHidden),
  endUserChat: compose(dispatch, endChat),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
