export const SET_OPEN_ZENDESK = 'zendesk/SET_OPEN_ZENDESK';
export const SET_HIDE_ZENDESK = 'zendesk/SET_HIDE_ZENDESK';
export const INIT_ACCOUNT_DATA_ZENDESK = 'zendesk/INIT_ACCOUNT_DATA_ZENDESK';
export const ACCOUNT_DATA_LOADED_ZENDESK = 'zendesk/ACCOUNT_DATA_LOADED_ZENDESK';
export const SEND_VISITOR_MESSAGE = 'zendesk/SEND_VISITOR_MESSAGE';
export const SEND_VISITOR_FILE = 'zendesk/SEND_VISITOR_FILE';
export const END_CHAT = 'zendesk/END_CHAT';

export const ZENDESK_CHAT_MEMBER_JOIN = 'zendesk/chat/MEMBER_JOIN';
export const ZENDESK_CHAT_MEMBER_LEAVE = 'zendesk/chat/MEMBER_LEAVE';
export const ZENDESK_CHAT_WAIT_QUEUE = 'zendesk/chat/WAIT_QUEUE';
export const ZENDESK_CHAT_QUEUE_POSITION = 'zendesk/chat/QUEUE_POSITION';
export const ZENDESK_CHAT_FILE = 'zendesk/chat/FILE';
export const ZENDESK_CHAT_MSG = 'zendesk/chat/MSG';
export const ZENDESK_CHAT_REQUEST_RATING = 'zendesk/chat/REQUEST_RATING';
export const ZENDESK_CHAT_RATING = 'zendesk/chat/RATING';
export const ZENDESK_CHAT_TYPING = 'zendesk/chat/TYPING';

export const zendeskEvents = {
  CONNECTION_UPDATE: 'zendesk/CONNECTION_UPDATE',
  ACCOUNT_STATUS: 'zendesk/ACCOUNT_STATUS',
  DEPARTMENT_UPDATE: 'zendesk/DEPARTMENT_UPDATE',
  VISITOR_UPDATE: 'zendesk/VISITOR_UPDATE',
  AGENT_UPDATE: 'zendesk/AGENT_UPDATE',
  CHAT: 'zendesk/CHAT',
};
