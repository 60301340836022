import { v as bvVar } from 'bv';

export const loggedOutTags = () => (
  [bvVar('zendeskConfig').brandName, bvVar('deviceInfo').deviceType]
);

export const loggedInTags = ({ accountNumber, csLevel, countryCode }) => ([
  bvVar('zendeskConfig').brandName,
  bvVar('deviceInfo').deviceType,
  `Account_Number: ${accountNumber}`,
  `CS_LEVEL: ${csLevel}`,
  `CountryCode: ${countryCode}`,
]);
