import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import ChatImage from './chat_image';

/* eslint-disable camelcase */
const ChatMedia = ({ message }) => (
  <div className="chat-media-container">
    {message?.attachment?.mime_type && /^image/.test(message.attachment.mime_type) ? (
      <ChatImage message={message} />
    ) : (
      <a href={message.attachment.url} target="_blank" rel="noopener noreferrer">
        <div className="chat-msg">
          {t('zendesk.chat.download_attachment', { name: message.attachment.name })}
        </div>
      </a>
    )}
  </div>
);

ChatMedia.propTypes = {
  message: PropTypes.instanceOf(Object).isRequired,
};

export default ChatMedia;
