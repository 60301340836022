import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import TopBar from '../top_bar';
import PreChatFormView from '../pre_chat_form/pre_chat_form_view';

const MessageUsView = ({
  sent, setFieldValue, message, onSubmit, name, email, formRef, sendAnother,
}) => (
  <>
    <TopBar title={t('zendesk.message_us')} />
    <div className="card-container offline-card">
      <div className="card-icon">
        <span className="zendesk-icon zendesk-message-icon" />
      </div>
      <div className={`card-content ${sent ? 'sent' : ''}`}>
        <div className="card-title" />
        {sent ? (
          <div key="sent" className="offline-sent">
            {t('zendesk.message_has_been_sent')}
            <button
              className="action-button button-resend"
              onClick={sendAnother}
              type="button"
            >
              {t('zendesk.send_another')}
            </button>
          </div>
        ) : (
          <PreChatFormView
            setFieldValue={setFieldValue}
            message={message}
            onSubmit={onSubmit}
            readOnly={false}
            name={name}
            email={email}
            formRef={formRef}
          />
        )}
      </div>
    </div>
  </>
);

MessageUsView.propTypes = {
  sent: PropTypes.bool.isRequired,
  formRef: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sendAnother: PropTypes.func.isRequired,
};

export default MessageUsView;
