import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ChatView from './chat_view';

const ChatContainer = ({
  isOffline, sendVisitorMessage, sendVisitorFile, endUserChat,
}) => {
  const [messageInput, setMessageInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const attachFileRef = useRef(null);

  const stopTyping = () => {
    if (!isTyping) return;

    window.zChat.sendTyping(false);
    setIsTyping(false);
  };

  const handleOnChange = ({ target: { value } }) => {
    setMessageInput(value);
    if (!isTyping) {
      window.zChat.sendTyping(true);
      setIsTyping(true);
    }
    stopTyping();
  };

  const handleOnSubmit = (evt) => {
    if (evt) evt.preventDefault();

    if (isOffline || !messageInput) return;

    stopTyping();
    window.zChat.sendChatMsg(messageInput);

    sendVisitorMessage(messageInput);
    setMessageInput('');
  };

  const uploadFile = (file) => {
    if (isOffline) return;

    const attachment = {
      mime_type: file.type,
      name: file.name,
      size: file.size,
      url: window.URL.createObjectURL(file),
    };

    window.zChat.sendFile(file);
    sendVisitorFile(attachment);
  };

  const handleFileUpload = (evt) => {
    evt.preventDefault();

    uploadFile(evt.dataTransfer.files[0]);
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
    setDragOver(true);
  };

  const onDrop = (evt) => {
    setDragOver(false);
    handleFileUpload(evt);
  };

  const onDragLeave = () => {
    setDragOver(false);
  };

  const attachFile = () => attachFileRef.current.click();

  const onFileAttached = () => {
    if (attachFileRef?.current?.files?.[0]) uploadFile(attachFileRef.current.files[0]);
  };

  const endChat = () => window.zChat.endChat({}, () => endUserChat());

  return (
    <ChatView
      handleOnSubmit={handleOnSubmit}
      handleOnChange={handleOnChange}
      dragOver={dragOver}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
      messageInput={messageInput}
      attachFileRef={attachFileRef}
      attachFile={attachFile}
      onFileAttached={onFileAttached}
      endChat={endChat}
    />
  );
};

ChatContainer.propTypes = {
  isOffline: PropTypes.bool.isRequired,
  sendVisitorMessage: PropTypes.func.isRequired,
  sendVisitorFile: PropTypes.func.isRequired,
  endUserChat: PropTypes.func.isRequired,
};

export default ChatContainer;
