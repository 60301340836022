import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLoggedIn } from 'bv-hooks';
import { loggedInTags, loggedOutTags } from '../../helpers/tags';
import MessageUsView from './message_us_view';

const MessageUsContainer = ({ accountData }) => {
  const loggedIn = useLoggedIn();
  const formRef = useRef(null);
  const [sent, setSent] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (loggedIn) {
      setName(accountData.name);
      setEmail(accountData.email);
      window.zChat.addTags(loggedInTags(accountData));
    } else {
      window.zChat.addTags(loggedOutTags());
    }
  }, [loggedIn]);

  const onSubmit = (evt) => {
    evt.preventDefault();

    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }

    window.zChat.sendOfflineMsg({
      name,
      email,
      message,
    }, (err) => {
      if (err) return;

      setSent(true);
    });
  };

  const sendAnother = () => {
    setMessage('');
    setSent(false);
  };

  const setFieldValue = ({ target: { id, value } }) => {
    const mapping = {
      name: setName,
      email: setEmail,
      message: setMessage,
    };

    mapping[id](value);
  };

  return (
    <MessageUsView
      sent={sent}
      setFieldValue={setFieldValue}
      message={message}
      onSubmit={onSubmit}
      name={name}
      email={email}
      formRef={formRef}
      sendAnother={sendAnother}
    />
  );
};

MessageUsContainer.propTypes = {
  accountData: PropTypes.instanceOf(Object).isRequired,
};

export default MessageUsContainer;
