import PropTypes from 'prop-types';
import ChatMessage from '../chat_message';
import TypingAgents from '../typing_agents';
import SystemMessage from '../system_message';
import QueuePosition from '../queue_position';
import RatingMessage from '../rating_message';

const MessageListView = ({
  agents,
  messages,
  queuePosition,
  messagesListRef,
  sendVisitorMessage,
  hasRating,
  lastRatingRequestTimestamp,
}) => (
  <div className="message-list-container" ref={messagesListRef}>
    <div>
      {messages.map((msg, i) => {
        if (['chat.file', 'chat.msg'].includes(msg.type)) {
          const prevMsg = messages[i - 1];
          return (
            <ChatMessage
              key={`${msg.type}${msg.timestamp}`}
              message={msg}
              sibling={prevMsg?.type === msg.type && msg.nick && msg.nick === prevMsg?.nick}
              agent={agents[msg.nick]}
              sendVisitorMessage={sendVisitorMessage}
            />
          );
        } if (['chat.memberjoin', 'chat.memberleave', 'typing', 'chat.rating'].includes(msg.type)) {
          return (
            <SystemMessage
              key={`${msg.type}${msg.timestamp}`}
              message={msg}
            />
          );
        } if (msg.type === 'chat.request.rating') {
          return (
            <RatingMessage
              key={`${msg.type}${msg.timestamp}`}
              agent={agents[msg.nick]}
              hasRating={hasRating}
              shouldDisplay={msg.timestamp === lastRatingRequestTimestamp}
            />
          );
        }
        return null;
      })}
    </div>
    <QueuePosition position={queuePosition} />
    <TypingAgents agents={agents} />
  </div>
);

MessageListView.propTypes = {
  agents: PropTypes.instanceOf(Object).isRequired,
  messages: PropTypes.arrayOf(Object).isRequired,
  queuePosition: PropTypes.number.isRequired,
  messagesListRef: PropTypes.instanceOf(Object).isRequired,
  sendVisitorMessage: PropTypes.func.isRequired,
  hasRating: PropTypes.bool.isRequired,
  lastRatingRequestTimestamp: PropTypes.number.isRequired,
};

export default MessageListView;
