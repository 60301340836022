import { ChatLoading } from 'bv-components';

import { useState } from 'react';

const Loading = () => {
  const [liveChatIsLoading, setLiveChatIsLoading] = useState(true);

  const hideChatPreloader = () => {
    setLiveChatIsLoading(false);
  };

  return liveChatIsLoading ? (
    <ChatLoading onHideChatPreloader={hideChatPreloader} />
  ) : null;
};

export default Loading;
