import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLoggedIn } from 'bv-hooks';
import { loggedInTags, loggedOutTags } from '../../helpers/tags';
import PreChatFormView from './pre_chat_form_view';

const PreChatFormContainer = ({ accountData, sendVisitorMessage }) => {
  const loggedIn = useLoggedIn();
  const formRef = useRef(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (loggedIn) {
      setName(accountData.name);
      setEmail(accountData.email);
      window.zChat.addTags(loggedInTags(accountData));
    } else {
      window.zChat.addTags(loggedOutTags());
    }
  }, [loggedIn]);

  const onSubmit = (evt) => {
    evt.preventDefault();

    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }

    if (!message) return;

    window.zChat.setVisitorInfo({
      display_name: name,
      email,
    }, () => {
      window.zChat.sendChatMsg(message);
    });

    sendVisitorMessage(message);
  };

  const setFieldValue = ({ target: { id, value } }) => {
    const mapping = {
      name: setName,
      email: setEmail,
      message: setMessage,
    };

    mapping[id](value);
  };

  return (
    <PreChatFormView
      formRef={formRef}
      name={name}
      email={email}
      message={message}
      readOnly={loggedIn}
      setFieldValue={setFieldValue}
      onSubmit={onSubmit}
    />
  );
};

PreChatFormContainer.propTypes = {
  accountData: PropTypes.instanceOf(Object).isRequired,
  sendVisitorMessage: PropTypes.func.isRequired,
};

export default PreChatFormContainer;
