import PropTypes from 'prop-types';
import Avatar from './avatar';
import ChatMedia from './chat_media';

const ChatMessage = ({ message, agent, sibling }) => (
  <div className={`chat-msg-container ${message.member_type} ${sibling ? 'sibling' : ''}`}>
    <div className="avatar-container">
      <Avatar entity={agent} />
    </div>
    <div className="chat-msg-wrapper">
      {message.type === 'chat.file' ? <ChatMedia message={message} /> : (
        <div className="chat-msg">
          <span>{message.msg}</span>
        </div>
      )}
    </div>
  </div>
);

ChatMessage.propTypes = {
  message: PropTypes.instanceOf(Object).isRequired,
  agent: PropTypes.instanceOf(Object).isRequired,
  sibling: PropTypes.bool.isRequired,
};

export default ChatMessage;
