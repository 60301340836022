import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const QueuePosition = ({ position }) => (
  position <= 0 ? null : (
    <div className="system-msg-container">
      <span className="system-msg">
        {t('zendesk.chat.queue_position', { position })}
      </span>
    </div>
  )
);

QueuePosition.propTypes = {
  position: PropTypes.number.isRequired,
};

export default QueuePosition;
