import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'bv-i18n';
import MessageList from '../message_list';

const ChatView = ({
  handleOnSubmit, handleOnChange, onDragOver, onDrop, onDragLeave, dragOver, messageInput,
  attachFileRef, attachFile, onFileAttached, endChat,
}) => {
  const styles = classNames('input-container', {
    'drag-drop-zone': dragOver,
  });

  return (
    <>
      <MessageList />
      <div className={styles} onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
        <form className="input-form" onSubmit={handleOnSubmit}>
          <input ref={attachFileRef} type="file" hidden onChange={onFileAttached} />
          <div className="zendesk-icon-wrapper" onClick={attachFile}><span className="zendesk-icon zendesk-attach-icon" /></div>
          <input
            value={messageInput}
            className="input"
            placeholder={t('zendesk.chat.input_placeholder')}
            onChange={handleOnChange}
          />
          <div className="send-button" onClick={handleOnSubmit}>
            <span className="zendesk-icon zendesk-send-icon" />
          </div>
          <div className="zendesk-icon-wrapper" onClick={endChat}><span className="zendesk-icon zendesk-exit-icon" /></div>
        </form>
      </div>
    </>
  );
};

ChatView.propTypes = {
  dragOver: PropTypes.bool.isRequired,
  messageInput: PropTypes.string.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  attachFile: PropTypes.func.isRequired,
  onFileAttached: PropTypes.func.isRequired,
  endChat: PropTypes.func.isRequired,
  attachFileRef: PropTypes.instanceOf(Object).isRequired,
};

export default ChatView;
