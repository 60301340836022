import PropTypes from 'prop-types';

const ChatImage = ({ message }) => (
  <a href={message.attachment.url} target="_blank" className="chat-img-container" rel="noopener noreferrer">
    <span
      className="chat-img"
      style={{
        backgroundImage: `url(${message.attachment.url})`,
      }}
    />
  </a>
);

ChatImage.propTypes = {
  message: PropTypes.instanceOf(Object),
};

ChatImage.defaultProps = {
  message: {
    attachment: {
      url: '',
    },
  },
};

export default ChatImage;
